import { FetchBase, fetchable } from './FetchBase'
import { Product } from './Product'
import RecentsService from '@/modules/products/services/RecentsService';
import { IFetchable, IProduct, IRecents } from './types';

export class Recents extends FetchBase implements IFetchable<IRecents>, IRecents {
    public products: Product[] = []

    private recentsService = new RecentsService();

    @fetchable
    public async fetch(orgID: string) {
        const products: IProduct[] = await this.recentsService.getRecentProducts(orgID);

        this.products = [];

        for (let rawprod of products) {
            let newProd: Product = new Product()
            newProd.productId = rawprod.productId
            newProd.productCategory = rawprod.productCategory
            newProd.name = rawprod.name
            newProd.brand = rawprod.brand
            newProd.brandName = (rawprod.brandName) ? rawprod.brandName : ''
            newProd.isWip = rawprod.isWip
            newProd.isPublished = rawprod.isPublished
            newProd.workflowId = rawprod.workflowId

            this.products.push(newProd)
        }
    }
}
