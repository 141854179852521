import { FetchBase, fetchable } from './FetchBase'
import TodoService from '@/modules/products/services/TodoService';
import { IFetchable, ITodo, ITodoItem } from './types';

export class Todo extends FetchBase implements IFetchable<ITodo>, ITodo {
    public items: ITodoItem[] = []

    private todoService = new TodoService();

    @fetchable
    public async fetch(orgID: string) {
        this.items = await this.todoService.getTodoItems(orgID);
    }
}
