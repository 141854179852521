import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import {  } from '@/domain/types';

export default class FeeService extends BaseService {
    private apiBase: string;

    constructor() { super(); this.apiBase = store.state.apiBase; }

    public async createFee(orgID: string, fee: any): Promise<any> {

        const apiConfig: Axios.AxiosRequestConfig = {
        method: "post",
        url: `${this.apiBase}/private/organisations/${orgID}/feesLibrary`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: fee
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);
        if (res.status === 200) {
            store.state.toastText = "Fee Created Successfully";
            store.state.toastVisible = true;
        }
        return res.data;
    }

    public async getFees(orgID: string): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/feesLibrary`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }

    public async getFee(orgID: string, feeId: any): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${this.apiBase}/private/organisations/${orgID}/feesLibrary/${feeId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data;
    }

    public async editfee(orgID: string, feeId: string, fee: any): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
        method: "post",
        url: `${this.apiBase}/private/organisations/${orgID}/feesLibrary/${feeId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: fee
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);
        if (res.status === 200) {
            store.state.toastText = "Fee Updated Successfully";
            store.state.toastVisible = true;
        }
        return res.data;
    }

    public async deleteFee(orgID: string, feeId: string): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
        method: "delete",
        url: `${this.apiBase}/private/organisations/${orgID}/feesLibrary/${feeId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);
        if (res.status === 200) {
            store.state.toastText = "Fee Deleted Successfully";
            store.state.toastVisible = true;
        }
        return res.data;
    }
}