import { FetchBase, fetchable } from './FetchBase'
import HistoryService from '@/modules/products/services/HistoryService';
import { IFetchable, IHistory, IEvent } from './types';

export class History extends FetchBase implements IFetchable<IHistory>, IHistory {
    public events: IEvent[] = []

    private historyService = new HistoryService();

    @fetchable
    public async fetch(orgID: string, productId: string) {
        this.events = await this.historyService.getProductHistory(orgID, productId);
    }
}
