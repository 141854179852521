import { FetchBase, fetchable } from './FetchBase'
import FileService from '@/modules/products/services/FileService';
import { IFetchable } from './types';

export class File extends FetchBase implements IFetchable<any> {
    private fileService = new FileService();
    public data: any = ""; 
    public type = "";
    public name = "";
    public size = 0;

    @fetchable
    public async fetch(organisationId: string, fileId: string) {
        let res = await this.fileService.getFile(organisationId, fileId)
        this.type = res.headers['content-type']
        this.data = res.data
        this.size = res.headers['content-length'];
    }
}
