import { FetchBase, fetchable } from './FetchBase'
import OrganisationService from '@/modules/products/services/OrganisationService'
import TmdService from "@/modules/products/services/TmdService";
import { IFetchable, IOrganisation, IPortfolio, IOrgSettings, IOrganisationFeatures, ITargetMarketPortfolio } from './types'
import { Portfolio } from './Portfolio'
import { TargetMarketPortfolio } from './TargetMarketPortfolio';

export class Organisation extends FetchBase implements IFetchable<IOrganisation>, IOrganisation {
    private static defaultIconUri = 'img/theme/default-org.png'
    public organisationId = ''
    public iconUri = Organisation.defaultIconUri
    public name = ''
    public portfolio: IPortfolio & IFetchable<IPortfolio> = new Portfolio()
    public settings: IOrgSettings = {}
    public features: IOrganisationFeatures | undefined = {}
    public tmdPortfolio: ITargetMarketPortfolio & IFetchable<ITargetMarketPortfolio> = new TargetMarketPortfolio();

    private organisationService = new OrganisationService();
    private targetMarketService = new TmdService();

    @fetchable
    public async fetch(orgID: string) {
        const org = await this.organisationService.getOrganisation(orgID);

        this.organisationId = org.organisationId
        this.iconUri = (org.iconUri && org.iconUri !== '') ? org.iconUri : Organisation.defaultIconUri
        this.name = org.name
        this.features = org.features;

        this.settings = await this.organisationService.getSettings(orgID);

        this.tmdPortfolio.fetch(this.organisationId);

        this.portfolio.fetch(this.organisationId);
    }
}
