import { FetchBase, fetchable } from './FetchBase'
import OrganisationService from '@/modules/products/services/OrganisationService';
import { IFetchable, IOrganisationSettingsEvent, IOrganisationSettingsEvents } from './types';

export class SettingsEvents extends FetchBase implements IFetchable<IOrganisationSettingsEvents>, IOrganisationSettingsEvents {
    public settingsEvents: IOrganisationSettingsEvent[] = [];

    private eventsService = new OrganisationService();

    @fetchable
    public async fetch(orgID: string) {
        this.settingsEvents = await this.eventsService.getSettingsHistory(orgID);
    }
}