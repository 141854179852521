import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a0924e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-header-spacing" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "d-flex align-items-center flex-column ms-1 icon-div"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_search_input = _resolveComponent("base-search-input")!
  const _component_CaretUpOutlined = _resolveComponent("CaretUpOutlined")!
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.searchEnable)
        ? (_openBlock(), _createBlock(_component_base_search_input, {
            key: 0,
            placeholder: "Search for a product by name",
            modelValue: _ctx.searchString,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event)),
            className: "ps-2 mb-2 mt-1"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["local-table", {'scroll': !_ctx.noScroll}])
    }, [
      _createElementVNode("table", {
        class: _normalizeClass(["table table-sm", {'stickyHeader': _ctx.stickyHeader, 'table-hover': _ctx.rowSelectable}])
      }, [
        _createElementVNode("thead", {
          class: _normalizeClass(["table-light align-middle", [`table-${_ctx.headVariant}`]])
        }, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
              return (_openBlock(), _createElementBlock("th", {
                scope: "col",
                key: column.key
              }, [
                _createElementVNode("div", {
                  onClick: ($event: any) => (column.sortable && _ctx.sortBy(column.key)),
                  class: "d-flex align-items-center"
                }, [
                  _createTextVNode(_toDisplayString(column.label) + " ", 1),
                  (column.sortable)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("span", {
                          class: _normalizeClass([{'text-dark': _ctx.sortColumn === column.key && _ctx.sortOrder === 'asc'}, "icon-span"])
                        }, [
                          _createVNode(_component_CaretUpOutlined)
                        ], 2),
                        _createElementVNode("span", {
                          class: _normalizeClass([{'text-dark': _ctx.sortColumn === column.key && _ctx.sortOrder === 'desc'}, "icon-span"])
                        }, [
                          _createVNode(_component_CaretDownOutlined)
                        ], 2)
                      ]))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_2)
              ]))
            }), 128))
          ])
        ], 2),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredData, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: item.id,
              onClick: ($event: any) => (_ctx.rowSelected(item)),
              class: _normalizeClass({ 'selected-row': _ctx.isSelectedRow(item, _ctx.rowSelectable) })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: column.key
                }, [
                  _renderSlot(_ctx.$slots, column.key, _mergeProps({ ref_for: true }, { item, column, index }), () => [
                    _createTextVNode(_toDisplayString(column.formatter ? column.formatter(item[column.key]) : item[column.key]), 1)
                  ], true)
                ]))
              }), 128))
            ], 10, _hoisted_4))
          }), 128))
        ])
      ], 2)
    ], 2)
  ]))
}