import { FetchBase, fetchable } from './FetchBase'
import { IFetchable , IRoadmap } from '@/domain/types'
import RoadmapService from '@/modules/products/services/RoadmapService';

export class Roadmaps extends FetchBase implements IFetchable<IRoadmap[]> {
    public roadmaps: IRoadmap[] = [];

    private roadmapService = new RoadmapService();

    @fetchable
    public async fetch() {
        this.roadmaps = await this.roadmapService.getAllRoadmaps();
    }
}
