import { FetchBase, fetchable } from './FetchBase'
import { ITargetMarket, IFetchable, ITargetMarketWorkflow, WorkflowState, WorkflowUpdateType, WorkflowType, IWorkflowComment, IAttachmentId } from './types';
import TmdWorkflowService from '@/modules/products/services/TmdWorkflowService';

export class TargetMarketWorkflow extends FetchBase implements IFetchable<ITargetMarketWorkflow>, ITargetMarketWorkflow {
    public workflowId = '';
    public organisationId = '';
    public tmdId = '';
    public createdAt = '';
    public userId = '';
    public assignedTo = '';
    public state: WorkflowState = WorkflowState.OPEN;
    public type: WorkflowType = WorkflowType.PUBLISH;
    public futureDate?: string = undefined;
    public comments!: IWorkflowComment[];
    public attachments?: any[] | undefined;
    public isGroupWorkflow?: boolean;
    public tmdIds?: string[];

    private workflowService = new TmdWorkflowService();

    public static async createWorkflow(organisationId: string, tmdId: string | undefined, isGroupWorkflow: boolean, tmdIds: string[] | [], comment: string, workflowType: string, futureDate: string | undefined): Promise<string> {
       let workflowService = new TmdWorkflowService();

       return workflowService.createTmdWorkflow(organisationId, tmdId, isGroupWorkflow, tmdIds, comment, workflowType, futureDate)
    }

    public async updateTmdWorkflow(type: WorkflowUpdateType, comment: string, assignedTo: string, attachments?: IAttachmentId[]) {
        await this.workflowService.updateTmdWorkflow(this.organisationId, this.workflowId, type, comment, assignedTo, attachments);
    }

    @fetchable
    public async fetch(orgID: string, workflowId: string) {
        let workflow = await this.workflowService.getWorkflow(orgID, workflowId);
        this.workflowId = workflow.workflowId;
        this.tmdId = workflow.tmdId;
        this.createdAt = workflow.createdAt;
        this.userId = workflow.userId;
        this.assignedTo = workflow.assignedTo;
        this.state = workflow.state;
        this.type = (workflow.type) ? workflow.type : WorkflowType.PUBLISH;
        this.futureDate = workflow.futureDate ? workflow.futureDate : undefined;
        this.comments = workflow.comments;
        this.organisationId = workflow.organisationId;
        this.attachments = workflow.attachments ? workflow.attachments : undefined;
        this.isGroupWorkflow = workflow.isGroupWorkflow ? workflow.isGroupWorkflow : undefined
        this.tmdIds = workflow.tmdIds
    }
}
