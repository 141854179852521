import { FetchBase, fetchable } from './FetchBase'
import WorkflowService from '@/modules/products/services/WorkflowService';
import { IFetchable, IWorkflowValidate } from './types';

export class WorkflowValidate extends FetchBase implements IFetchable<IWorkflowValidate>, IWorkflowValidate {
    private workflowService = new WorkflowService();
    public status = 0;

    @fetchable
    public async fetch(organisationId: string, productId: string | undefined,isGroupWorkflow: boolean,productIds: string[] | [], comment: string, workflowType: string, futureDate: string | undefined) {
        const validation = await this.workflowService.validateWorkflow(organisationId, productId, isGroupWorkflow, productIds, comment, workflowType, futureDate);
        
        this.status = validation.status;
    }
}
