import * as Generated from './generated'
import { FetchBase, fetchable } from './FetchBase'
import MarketHistoryService from '@/modules/products/services/MarketHistoryService';
import { IFetchable, IMarketProductAudit } from './types';

export class MarketProductAudit extends FetchBase implements IFetchable<IMarketProductAudit>, IMarketProductAudit {
    public auditId = '';
    public productId = '';
    public holderId = '';
    public eventType = '';
    public product?: Generated.Product = undefined;

    private historyService = new MarketHistoryService();

    @fetchable
    public async fetch(auditId: string) {
        let result: any = await this.historyService.getProductAudit(auditId);

        this.auditId = result?.auditId;
        this.productId = result?.productId;
        this.holderId = result?.holderId;
        this.product = result?.product;
        this.eventType = result?.eventType;
    }
}
