import * as Generated from './generated'
import { FetchBase, fetchable } from './FetchBase'
import TargetMarketHistoryService from '@/modules/products/services/TmdHistoryService';
import { IFetchable, ITmdEventData, ITargetMarket } from './types';

export class TargetMarketEvent extends FetchBase implements IFetchable<ITmdEventData>, ITmdEventData {
    public eventId = '';
    public tmdId = '';
    public organisationId = '';
    public createdAt = '';
    public userId = '';
    public isPublished = false;
    public market?: Generated.TargetMarket = undefined;

    private targetMarketHistoryService = new TargetMarketHistoryService();

    @fetchable
    public async fetch(orgID: string, tmdId: string, eventId: string) {
        let result: ITmdEventData = await this.targetMarketHistoryService.getTargetMarketEvent(orgID, tmdId, eventId);
        this.eventId = result.eventId;
        this.tmdId = result.tmdId;
        this.organisationId = result.organisationId;
        this.createdAt = result.createdAt;
        this.userId = result.userId;
        this.isPublished = result.isPublished;
        this.market = result.market;
    }
}
