import store from '@/store';
import { FetchBase, fetchable } from './FetchBase'
import WorkflowService from '@/modules/products/services/WorkflowService';
import { IFetchable, IReviews, IWorkflow, WorkflowState } from './types';

export class ReviewList extends FetchBase implements IFetchable<IReviews>, IReviews {
    public assigned: IWorkflow[] = []
    public created: IWorkflow[] = []

    private workflowService = new WorkflowService();

    @fetchable
    public async fetch(orgID: string) {
        let workflows: IWorkflow[] = await this.workflowService.getWorkflows(orgID);

        this.assigned = [];
        this.created = [];

        for (let i = 0; i < workflows.length; i++) {
            if (workflows[i].state !== WorkflowState.CLOSED) {
                if (workflows[i].assignedTo === store.state.$auth.user.userId) {
                    this.assigned.push(workflows[i]);
                } else if (workflows[i].userId === store.state.$auth.user.userId) {
                    this.created.push(workflows[i]);
                }
            }
        }
    }
}
