import { FetchBase, fetchable } from './FetchBase'
import OrganisationReportService from '@/modules/products/services/OrganisationReportService';
import { IFetchable, IOrganisationReport, IOrganisationReports } from './types';

export class OrganisationReport extends FetchBase implements IFetchable<IOrganisationReports>, IOrganisationReports {
    public reports: IOrganisationReport[] = [];

    private organisationReportService = new OrganisationReportService();

    @fetchable
    public async fetch(orgID: string) {
        this.reports = await this.organisationReportService.getStandardReports(orgID);
    }
}
