export enum AuthStatus {
    SUCCESS = 'SUCCESS',
    INACTIVE = 'INACTIVE',
    FAILURE = 'FAILURE',
    LOCKED = 'LOCKED',
    CHALLENGE = 'CHALLENGE',
    SEEK_MFA = 'SEEK_MFA',
    FORCE_MFA = 'FORCE_MFA'
}

export interface IAuthResult {
    status: AuthStatus;
    token?: string;
    challenge?: {
        challengeId: string,
        token: string
    }
}
