<template>
  <div v-if="slots.label || label" class="row mb-1">
    <div class="col">
      <slot name="label">
        <span :class="['data-h', {'data-mandatory': required}]">{{ label }}</span>
      </slot>
      <warning-icon v-if="isWarning" :toolTipTitle="warningTooltip" />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <textarea 
        :id="id"
        :class="[
          'data-v',
          className,
          'form-control',
          `form-control-${size}`,
          { 'error-border': highlightError }
        ]"
        v-model="inputModel"
        :placeholder="placeholder" 
        :rows="rows"
        :disabled="disabled">
      </textarea>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, useSlots } from "vue";
import WarningIcon from '@/modules/products/components/WarningIcon.vue';

export default defineComponent({
  name: 'form-text-area',
  props: {
    id: {
      type: String
    },
    size: {
      type: String,
      default: "sm",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    rows: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    warningToolTipTitle: {
      type: String
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false
    },
    showWarning: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    },
    highlightError: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WarningIcon
  },
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {

    const slots = useSlots()

    const inputModel = computed({
      get() {
        return props.modelValue;
      },
      set(value: any) {
        emit("update:modelValue", value);
        emit("change",value)
      },
    });

    const isWarning = computed(() => {
      if (props.required && !inputModel.value) {
        return true;
      }
      if (props.showWarning) return true;
      return false;
    })

    const warningTooltip = computed(() => {
      if (props.required && !inputModel.value) {
        return 'mandatoryField'
      }
      else {
        return props.warningToolTipTitle
      }
    })

    return {
      inputModel,
      slots,
      isWarning,
      warningTooltip
    };
  },
});
</script>

<style scoped lang="scss">
.error-border {
  border: 1px solid red !important;
}
</style>
