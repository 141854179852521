import { FetchBase, fetchable } from './FetchBase'
import { IFetchable, IGovernanceWorkflow, IGovernanceWorkflows } from './types';
import GovernanceWorkflowService from '@/modules/products/services/GovernanceWorkflowService'
import { GovernanceWorkflow } from '@/domain';
import { workflow } from '@/vuex';

export class GovernanceWorkflowPortfolio extends FetchBase implements IFetchable<IGovernanceWorkflows>, IGovernanceWorkflows {
    private governanceWorkflowService = new GovernanceWorkflowService();
    public workflows: IGovernanceWorkflow[] = [];
    @fetchable
    public async fetch(orgId: string) {
        const workflows: IGovernanceWorkflow[] = await this.governanceWorkflowService.getAllGovernanceWorkflows(orgId) as IGovernanceWorkflow[];

        this.workflows = workflows;
    }
}
