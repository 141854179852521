import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IFeeEvent } from '@/domain/types';

export default class FeeHistoryService extends BaseService{
    public async getFeeEvents(orgID: string, feeId: string): Promise<IFeeEvent[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/feesLibrary/${feeId}/events`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IFeeEvent[];
    }

    public async getFeeEvent(orgID: string, feeId: string, eventId: string): Promise<IFeeEvent>{
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/feesLibrary/${feeId}/events/${eventId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IFeeEvent;
    }
}