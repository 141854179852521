import * as Generated from './generated'
import { FetchBase, fetchable } from './FetchBase'
import ProductService from "@/modules/products/services/ProductService"
import { IProduct, IFetchable } from './types';

export class Product extends FetchBase implements IFetchable<IProduct>, IProduct {
    public productId = '';
    public productCategory: Generated.ProductCategory | undefined = undefined;
    public name = '';
    public brand = '';
    public brandName = '';

    public publishedVersion?: Generated.Product = undefined;
    public wipVersion?: Generated.Product = undefined;
    public isWip = false;
    public isPublished = false;
    public workflowId?: string = undefined;
    public isEffective?: boolean = undefined;
    public lastUpdated?: string = undefined;
    public isDecommissioned?: boolean = undefined;
    public xPcOffMarket?: boolean = undefined;
    public xPcNonCDR?: boolean = undefined;

    private productService: ProductService = new ProductService();

    @fetchable
    public async fetch(organisationID: string, productID: string) : Promise<void> {
        this.reset();

        try {
            let product = await this.productService.getProduct(organisationID, productID);

            this.productId = product.productId;
            this.productCategory = product.productCategory;
            this.name = product.name;
            this.brand = product.brand;
            this.brandName = product.brandName;
            this.publishedVersion = product.publishedVersion;
            this.wipVersion = product.wipVersion;
            this.isWip = product.isWip;
            this.isPublished = product.isPublished;
            this.workflowId = product.workflowId;
            this.isEffective = product.isEffective;
            this.lastUpdated = product.lastUpdated;
            this.isDecommissioned = product.isDecommissioned;
            this.xPcOffMarket = product.xPcOffMarket;
            this.xPcNonCDR = product.xPcNonCDR;
        } catch (_) {}
    }

    public convert(product: IProduct) {
        let prod: Generated.Product = Generated.Convert.toProduct(JSON.stringify(product))

        this.productId = prod.productId;
        this.productCategory = prod.productCategory;
        this.name = (prod.name) ? prod.name : '';
        this.brand = (prod.brand) ? prod.brand : '';
        this.brandName = (prod.brandName) ? prod.brandName : '';

        return prod;
    }

    private reset() {
        this.productId = '';
        this.productCategory = undefined;
        this.name = '';
        this.brand = '';
        this.brandName = '';

        this.publishedVersion = undefined;
        this.wipVersion = undefined;
        this.isPublished = false;
        this.isWip = false;
        this.workflowId = undefined;
    }
}
