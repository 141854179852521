import * as Generated from './generated'
import { FetchBase, fetchable } from './FetchBase'
import RatesService from '@/modules/products/services/RatesService';
import { IFetchable, IProductCategory, IProductRates, IProductRate } from './types';

export class ProductRates extends FetchBase implements IFetchable<IProductRates>, IProductRates {
    public productId: string = '';
    public organisationId: string = '';
    public name: string = '';
    public fees: string = '';
    public productCategory: IProductCategory = IProductCategory.CRED_AND_CHRG_CARDS;

    public rates: IProductRate[] = [];

    private ratesService = new RatesService();
    @fetchable
    public async fetch(orgID: string) {
        this.rates = await this.ratesService.getProductRates(orgID);
    }
}
