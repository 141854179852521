import { FetchBase, fetchable } from './FetchBase'
import GovernanceWorkflowService from '@/modules/products/services/GovernanceWorkflowService';
import { IGovernanceWorkflow, IFetchable, IOutcome, IBoundworkflows } from './types';

export class GovernanceWorkflow extends FetchBase implements IFetchable<IGovernanceWorkflow>, IGovernanceWorkflow {
    public govWorkflowId = "";
    public title = "";
    public organisationId = "";
    public createdAt = "";
    public userId = "";
    public assignedTo = "";
    public state: any;
    public reason = "";
    public futureDate?: string;
    public deadlineDate = "";
    public comments: any[] = [];
    public attachments: any[] = [];
    public boundWorkflows: IBoundworkflows = {};
    public expectedOutcome: IOutcome[] = []
    public actualOutcome: IOutcome[] = [];
    public workflow: any = {};
    private governanceWorkflowService = new GovernanceWorkflowService();

    @fetchable
    public async fetch(organisationId: string, workflowId: string) {
        let workflow = await this.governanceWorkflowService.getGovernanceWorkflow(organisationId, workflowId)
        this.title = workflow.title;
        this.workflow = workflow;
        this.govWorkflowId = workflow.govWorkflowId ? workflow.govWorkflowId : "";
        this.assignedTo = workflow.assignedTo ? workflow.assignedTo : "";
        this.organisationId = workflow.organisationId ? workflow.organisationId : "";
        this.createdAt = workflow.createdAt ? workflow.createdAt : "";
        this.userId = workflow.userId ? workflow.userId : "";
        this.state = workflow.state ? workflow.state : "";
        this.futureDate = workflow.futureDate ? workflow.futureDate : "";
        this.deadlineDate = workflow.deadlineDate ? workflow.deadlineDate : "";
        this.comments = workflow.comments ? workflow.comments : [];
        this.attachments = workflow.attachments ? workflow.attachments : [];
        this.boundWorkflows = workflow.boundWorkflows ? workflow.boundWorkflows : {};
        this.expectedOutcome = workflow.expectedOutcome ? workflow.expectedOutcome : [];
        this.actualOutcome = workflow.actualOutcome ? workflow.actualOutcome : []
        this.reason = workflow.reason ? workflow.reason : ""
    }
}
