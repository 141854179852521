import { IUserLocalStorage } from "./domain/types/IUserLocalStorage";

const LAST_ACCESSED_ORG_ID_KEY = 'org-id';

export function saveOrgIdToLocalStorage(lastOrgId: string, userId: string) {
  // If var names are the same as paremeter names no more code is required
  const data = {
    lastOrgId,
    userId
  }
  localStorage.setItem(LAST_ACCESSED_ORG_ID_KEY, JSON.stringify(data));
}

export function loadOrgIdFromLocalStorage(): IUserLocalStorage {
  const storedLastOrg = localStorage.getItem(LAST_ACCESSED_ORG_ID_KEY);
  return storedLastOrg ? JSON.parse(storedLastOrg) : null;
}