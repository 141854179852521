import { FetchBase, fetchable } from './FetchBase'
import { IFetchable, IGovernanceWorkflowTemplate, IGovernanceWorkflowTemplates } from './types';
import GovernanceWorkflowService from '@/modules/products/services/GovernanceWorkflowService'
import { GovernanceWorkflow } from '@/domain';
import { workflow } from '@/vuex';

export class GovernanceWorkflowTemplates extends FetchBase implements IFetchable<IGovernanceWorkflowTemplates>, IGovernanceWorkflowTemplates {
    private governanceWorkflowService = new GovernanceWorkflowService();
    public templates: IGovernanceWorkflowTemplate[] = [];
    @fetchable
    public async fetch(orgId: string) {
        const templates: IGovernanceWorkflowTemplate[] = await this.governanceWorkflowService.getAllGovernanceWorkflowTemplates(orgId) as IGovernanceWorkflowTemplate[];

        this.templates = templates;
    }
}
