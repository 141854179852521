import { FetchBase, fetchable } from './FetchBase'
import EventsService from '@/modules/products/services/EventsService';
import { IFetchable, IFutureEvents, IFutureEvent } from './types';

export class FutureEventsByDate extends FetchBase implements IFetchable<IFutureEvents>, IFutureEvents {
    public events: IFutureEvent[] = [];

    private eventsService = new EventsService();

    @fetchable
    public async fetch(orgID: string, date: string) {
        this.events = await this.eventsService.getFutureEventsByDate(orgID, date);
    }
}
