import { FetchBase, fetchable } from './FetchBase'
import ComparisonReportService from '@/modules/products/services/ComparisonReportService';
import { IFetchable, IComparisonReport} from './types';
import { ILendingRatesReportFilters } from '@/domain/types'

export class ComparisonReport extends FetchBase implements IFetchable<any> {
    public report:IComparisonReport[] = [];

    private comparisonReportService = new ComparisonReportService();

    @fetchable
    public async fetch(orgID: string, filters: ILendingRatesReportFilters) {
        this.report = await this.comparisonReportService.getComparisonReports(orgID, filters);
    }
}
