import { FetchBase, fetchable } from './FetchBase'
import IndustryService from '@/modules/products/services/IndustryService';
import { IMarketPortfolio, IFetchable } from './types';
import * as Generated from '@/domain/generated'

export class MarketPortfolio extends FetchBase implements IFetchable<IMarketPortfolio>, IMarketPortfolio {
    public holderId = ''
    public products: {
        productId: string;
        name: string;
        productCategory: Generated.ProductCategory;
        brand: string;
        brandName?: string;
    }[] = []

    private industryService = new IndustryService();

    @fetchable
    public async fetch(brand: string) {
        this.holderId = brand;
        let portfolio = await this.industryService.getMarketPortfolio(brand);
        this.products = portfolio.products;
    }
}
