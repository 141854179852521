<template>
  <div>
    <div class="table-header-spacing">
      <base-search-input v-if="searchEnable" placeholder="Search for a product by name" v-model="searchString" className="ps-2 mb-2 mt-1"/>
    </div>
    
    <div class="local-table" :class="{'scroll': !noScroll}">
      <table class="table table-sm" :class="{'stickyHeader': stickyHeader, 'table-hover': rowSelectable}">
        <thead class="table-light align-middle" :class="[`table-${headVariant}`]">
          <tr>
            <th scope="col" v-for="column in columns" :key="column.key">
              <div @click="column.sortable && sortBy(column.key)" class="d-flex align-items-center">
                {{ column.label }}
                <div v-if="column.sortable" class="d-flex align-items-center flex-column ms-1 icon-div">
                  <span :class="{'text-dark': sortColumn === column.key && sortOrder === 'asc'}" class="icon-span">
                    <CaretUpOutlined />
                  </span> 
                  <span :class="{'text-dark': sortColumn === column.key && sortOrder === 'desc'}" class="icon-span">
                    <CaretDownOutlined />
                   </span>
                </div>
              </div>
            </th> 
          </tr>
        </thead>
        <tbody>
          <tr v-for="item,index in filteredData" :key="item.id" @click="rowSelected(item)" :class="{ 'selected-row': isSelectedRow(item, rowSelectable) }" >
            <td v-for="column in columns" :key="column.key">
              <slot :name="column.key" v-bind="{ item, column, index }">
                {{ column.formatter ? column.formatter(item[column.key]) : item[column.key] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent,PropType } from 'vue';
import { CaretUpOutlined, CaretDownOutlined  } from "@ant-design/icons-vue";

export interface TableColumn {
  key: string;
  label: string;
  sortable?: Boolean;
  formatter?: Function | any;
}

export enum TableVariant {
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  light = 'light',
  dark = 'dark'
}

export default defineComponent({
  name: 'base-table',
  props: {
    data: {
      type: Array as () => any[],
      default: [],
      required: true
    },
    columns: Array as () => TableColumn[],
    searchEnable: {
      type: Boolean,
      default: true
    },
    stickyHeader : {
      type: Boolean,
      default: false
    },
    headVariant: {
      type: String as PropType<TableVariant>,
      default: 'light',
    },
    noScroll: {
      type: Boolean,
      default: false
    },
    rowSelectable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CaretUpOutlined,
    CaretDownOutlined
  },
  setup(props, context) {
    const searchString = ref('');
    const sortColumn = ref('');
    const sortOrder = ref('asc');
    const selectedRow = ref();

    const filteredData = computed(() => {
      let result = JSON.parse(JSON.stringify(props.data));

      if (searchString.value) {
        result = search(result)
      }

      if (sortColumn.value) {
        result = applySort(result);
      }
      return result;
    });

    const formatCellValue = (value: string | number | boolean) => {
      if (value === undefined) {
        return '';
      }
      if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
      }
      return value.toString().toLowerCase();
    };

    const applySort = (data: any[]) => {
      return data.sort((a, b) => {
        const aValue = formatCellValue(a[sortColumn.value]);
        const bValue = formatCellValue(b[sortColumn.value]);

        return compareValues(aValue, bValue);
      });
    };

    const compareValues = (a: any, b: any) => {
      if (a === undefined || b === undefined) {
        // Handle undefined values
        return sortOrder.value === 'asc' ? (a === undefined ? 1 : -1) : (b === undefined ? -1 : 1);
      }
      return sortOrder.value === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    };

    const search = (data: any[]) =>{
      let regex: RegExp;
          try {
              regex = new RegExp(searchString.value, 'gi');
          } catch(_) {}
          return data.filter((product: any) => {
              if (product.name) return product.name.search(regex) !== -1
              return false;
          });
    }

    const sortBy = (key: string) => {
      if (sortColumn.value === key) {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      } else {
        sortColumn.value = key;
        sortOrder.value = 'asc';
      }
    };

    const clearSearch = () => {
      searchString.value = ''
    }

    const rowSelected = (item: any) => {
      selectedRow.value = item;
      context.emit('row-selected', item)
    }

    const isSelectedRow = (row: any, selectable: boolean) => {
      if (selectedRow.value && selectable) return JSON.stringify(selectedRow.value) == JSON.stringify(row);
      else return false;
    };


    return { 
      searchString, 
      sortColumn, 
      sortOrder, 
      filteredData, 
      sortBy,
      clearSearch,
      rowSelected,
      isSelectedRow
    };
  },
})
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
@import "~bootstrap/scss/variables";

.scroll {
  max-height: 500px;
  overflow-y: auto;
}

.local-table {
        margin: 5px;
    table {
      cursor: pointer;
        thead {
            th {
                color: $body-color;
                border-bottom: 2px solid #C4C4C4 !important;
              }
        }
        tr {
            background: #FFFFFF;
        }
    }
  }

.stickyHeader {
  thead {
    th {
      position: sticky;
      top: 0;
    }
  }
}

.table-header-spacing {
    margin: 0px 5px;
}
.icon-div {
  margin-bottom: 20px;
  color: #a6a6ae;
}

.icon-span {
  height: 9px;
}

.local-table table tr.selected-row {
  background-color: rgba(0, 0, 0, .075);
}
</style>
