import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01269480"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row mb-1"
}
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_warning_icon = _resolveComponent("warning-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.slots.label || _ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createElementVNode("span", {
                class: _normalizeClass(['data-h', { 'data-mandatory': _ctx.required }])
              }, _toDisplayString(_ctx.label), 3)
            ], true),
            (_ctx.isWarning)
              ? (_openBlock(), _createBlock(_component_warning_icon, {
                  key: 0,
                  toolTipTitle: _ctx.warningTooltip
                }, null, 8, ["toolTipTitle"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
          class: _normalizeClass(['form-select', 'data-v', `form-select-${_ctx.size}`,
          { 'error-border': _ctx.highlightError }]),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
          disabled: _ctx.disabled
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.value,
              value: option.value,
              disabled: option.disabled
            }, _toDisplayString(option.text), 9, _hoisted_6))
          }), 128))
        ], 42, _hoisted_5), [
          [_vModelSelect, _ctx.selectedValue]
        ])
      ])
    ])
  ], 64))
}