<template>
  <div :class="['form-check', className, {'form-switch': switchCheckbox}]">
    <input
      class="form-check-input"
      type="checkbox"
      :id="id"
      :value="value"
      :checked="isChecked"
      @change="handleChange"
      :disabled="disable"
    />

    <label class="form-check-label" :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, toRefs } from 'vue';

export default defineComponent({
  name: 'form-checkbox',
  props: {
    id: {
      type: String,
      default: `check-${Math.random().toString(36).substring(7)}`,
      required: true,
    },
    modelValue: {
      type: [Boolean, Array],
      required: true,
    },
    className: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, String],
      default: true,
    },
    switchCheckbox: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const { modelValue, value } = toRefs(props);

    const isChecked = computed(() => {
      if (Array.isArray(modelValue.value)) {
        return modelValue.value.includes(value.value);
      }
      return modelValue.value === value.value;
    });

    const handleChange = () => {
      if (Array.isArray(modelValue.value)) {
        const updatedArray = modelValue.value.includes(value.value)
          ? modelValue.value.filter((v) => v !== value.value)
          : [...modelValue.value, value.value];
        emit("update:modelValue", updatedArray);
      } else {
        emit("update:modelValue", !modelValue.value);
      }
    };

    return {
      isChecked,
      handleChange,
    };
  },
});
</script>

<style scoped>

.form-check .form-check-input {
  cursor: pointer;
}

.form-check-label {
  margin-left: 1.5rem 
}
</style>
