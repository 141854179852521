import Vue from "vue";
import Router, { NavigationGuardNext } from "vue-router";
import {
    createRouter,
    createWebHistory,
    RouteRecordRaw,
    RouteLocationNormalized,
    RouteLocationRaw
  } from 'vue-router'
import * as R from "vue-router";
import AppHeader from "@/framework/components/layout/AppHeader.vue";
import AppHeaderSimple from "@/framework/components/layout/AppHeaderSimple.vue";
import AppFooter from "@/framework/components/layout/AppFooter.vue";
import store from "@/store";

const guardSecure: R.NavigationGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: (to?: RouteLocationRaw | false | ((vm: typeof Vue) => any) | void) => void
) => {
    store.state.showSignIn = false;
    store.state.showSecure = true;
    try {
        const { viewType } = to.params || {};
        const previousRoutes = store.state.previousRoutes;

        if (viewType) {
            let path = to.fullPath.replace(viewType as string, '').replace(/\/$/, '');
            if (path !== from.fullPath && !store.state.backButtonRoute && !from.fullPath.includes('edit')) {
                store.state.previousRoute = from;
                previousRoutes.push(from);
            }
        } else if (!store.state.backButtonRoute && !from.fullPath.includes('edit')) {
            store.state.previousRoute = from;
            previousRoutes.push(from);
        }

        if (store.state.backButtonRoute) {
            previousRoutes.pop();
            store.state.backButtonRoute = false;
        }

        const normalizePath = (path: string) => path.replace(/\/(published|wip)$/, '');

        if (previousRoutes?.length) {
            const lastRoute = previousRoutes[previousRoutes.length - 1];
            if (normalizePath(lastRoute?.fullPath || '') === normalizePath(to.fullPath)) {
                previousRoutes.pop();
            }
        }
    } catch (error) {
        console.log("Error in route handling logic:", error);
    }

    
    
    try {
        if (to.fullPath == '/landing') {
            store.state.previousRoutes = []
        }
    } catch (error) {
        console.log("is this where the error comes from?")
    }
    

    // console.log("Route list: ", store.state.previousRoutes)

    const checkAuth = () => {
        if (store.state.$auth.isAuthenticated) {
            return next();
        } else {
            return next({name: 'Login', query: { targetUrl: to.fullPath }});
        }
    };

    // If loading has already finished, check our auth state using `fn()`
    try {
        if (!store.state.$auth.loading) {
            return checkAuth();
        }
    
        // Watch for the loading property to change before we check isAuthenticated
        store.state.$auth.$watch("loading", (loading: boolean) => {
            if (loading === false) {
                return checkAuth();
            }
        });
    } catch (error) {
        console.log("is it in auth checking?")
    }
    
};

const guardPublic: R.NavigationGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: (to?: RouteLocationRaw | false | ((vm: typeof Vue) => any) | void) => void
) => {
    store.state.showSignIn = true;
    store.state.showSecure = false;
    next();
};

const guardLogin = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    if (to.query['token']) {
        // Perform silent login with the token
        await store.state.$auth.silentLogin(to.query['token']);
        await store.state.$auth.completeLogin(to.query['targetUrl']);
    }
    else if (store.state.$auth.isAuthenticated) {
        next("/landing");
    } else {
        next();
    }
};

// Vue.use(Router);

export default createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkExactActiveClass: "active",
    routes: [
        {
            path: "/compliance",
            beforeEnter: guardPublic,
            name: "Compliance",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/public/Compliance.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/demo",
            beforeEnter: guardPublic,
            name: "Demo",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/public/Demo.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/enquire",
            beforeEnter: guardPublic,
            name: "Enquire",
            components: {
                header: AppHeader,
                default: () => import("./modules/common/views/public/Enquire.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/",
            beforeEnter: guardPublic,
            name: "Home",
            components: {
                default: () => import("./modules/products/views/public/Home.vue"),
            },
        },
        {
            path: "/insight",
            beforeEnter: guardPublic,
            name: "Insight",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/public/Insight.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/login",
            name: "Login",
            beforeEnter: guardLogin,
            components: {
                default: () => import("./framework/views/Login.vue"),
            },
        },
        {
            path: "/resetPassword/:resetId",
            name: "ResetPassword",
            beforeEnter: guardLogin,
            components: {
                default: () => import("./modules/common/views/public/ResetPassword.vue"),
            },
        },
        {
            path: "/sso",
            name: "sso",
            beforeEnter: guardSecure,
            components: {
                default: () => import("./modules/common/views/public/SSO.vue"),
            },
        },
        {
            path: "/logout",
            name: "Logout",
            components: {
                header: AppHeaderSimple,
                default: () => import("./framework/views/Logout.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/portfolio",
            beforeEnter: guardPublic,
            name: "Portfolio",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/public/Portfolio.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/landing",
            beforeEnter: guardSecure,
            name: "Landing",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/Landing.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/profile/:tabName",
            beforeEnter: guardSecure,
            name: "Profile",
            components: {
                header: AppHeader,
                default: () => import("./modules/common/views/public/Profile.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/release-notes",
            beforeEnter: guardSecure,
            name: "ReleaseNotes",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ReleaseNotes.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/roadmap",
            beforeEnter: guardSecure,
            name: "Roadmap",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/Roadmap.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/products/create",
            beforeEnter: guardSecure,
            name: "ProductsCreate",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductCreate.vue"),
            },
        },
        {
            path: "/org/:orgId/products/view",
            beforeEnter: guardSecure,
            name: "product-portfolio-page",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductPortfolio.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/products/rates",
            beforeEnter: guardSecure,
            name: "products-rates-page",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductsRates.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/portfolioValidation/:viewType?",
            beforeEnter: guardSecure,
            name: "product-validation",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductValidation.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/products/view/decommissioned",
            beforeEnter: guardSecure,
            name: "ProductDecommission",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductDecommission.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/products/view/rates",
            beforeEnter: guardSecure,
            name: "ProductRates",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductRates.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/products/view/:productId",
            beforeEnter: guardSecure,
            name: "ProductView",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductView.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/products/view/:productId/:viewType",
            beforeEnter: guardSecure,
            name: "ProductViewDirect",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductView.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/products/edit/:productId",
            beforeEnter: guardSecure,
            name: "ProductEdit",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductEdit.vue"),
            },
        },
        {
            path: "/org/:orgId/products/versions/:productId/:eventId1/compare/:eventId2",
            beforeEnter: guardSecure,
            name: "VersionCompare",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/VersionCompare.vue"),
            },
        },
        {
            path: "/org/:orgId/workflows/create/:productId",
            beforeEnter: guardSecure,
            name: "CreateWorkflow",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/CreateWorkflow.vue"),
            },
        },
        {
            path: "/org/:orgId/workflows/view/:workflowId",
            beforeEnter: guardSecure,
            name: "Workflow",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/Workflow.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/reviews",
            beforeEnter: guardSecure,
            name: "Reviews",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/Reviews.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/usage/external",
            beforeEnter: guardSecure,
            name: "UsageExternal",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/Construction.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/usage/internal",
            beforeEnter: guardSecure,
            name: "UsageInternal",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/Construction.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/insight/org/:orgId/products/view/:productId",
            beforeEnter: guardSecure,
            name: "InsightView",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/MarketProductView.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/insight/org/:orgId/products/view/:productId/:viewType",
            beforeEnter: guardSecure,
            name: "InsightViewDirect",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/MarketProductView.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/insight/org/:orgId/products/versions/:productId/:auditId1/compare/:auditId2",
            beforeEnter: guardSecure,
            name: "MarketVersionCompare",
            components: {
                header: AppHeader,
                default: () =>
                    import("./modules/products/views/secure/MarketVersionCompare.vue"),
            },
        },
        {
            path: "/insight/portfolio",
            beforeEnter: guardSecure,
            name: "market-portfolio-page",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/MarketPortfolios.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/insight/compare",
            beforeEnter: guardSecure,
            name: "InsightCompare",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/MarketComparison.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/insight/trend",
            beforeEnter: guardSecure,
            name: "InsightTrend",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/MarketTrends.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/insight/changes/:date?",
            beforeEnter: guardSecure,
            name: "InsightChanges",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/MarketChanges.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/insight/notifications",
            beforeEnter: guardSecure,
            name: "InsightNotifications",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/MarketNotifications.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/insight/report/:viewType?",
            beforeEnter: guardSecure,
            name: "MarketReports",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/MarketReports.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/settings/api/external/:orgId",
            beforeEnter: guardSecure,
            name: "EditExternalAPI",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ThirdPartyConfig.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/settings/api/external/:orgId/:viewType",
            beforeEnter: guardSecure,
            name: "EditExternalAPIDirect",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ThirdPartyConfig.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/settings/api/internal",
            beforeEnter: guardSecure,
            name: "EditInternalAPI",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/Construction.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/settings/api/:orgId/metrics/:tabName",
            beforeEnter: guardSecure,
            name: "UsageAnalytics",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/UsageAnalytics.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/settings/general/:tabName",
            beforeEnter: guardSecure,
            name: "EditOrganisationSettings",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/OrgSettings.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/settings/products/:tabName",
            beforeEnter: guardSecure,
            name: "EditProductSchema",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ProductSettings.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/settings/tmds/:tabName",
            beforeEnter: guardSecure,
            name: "EditTmdSchema",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdSettings.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/settings/:orgId/activity",
            beforeEnter: guardSecure,
            name: "UserActivity",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/UserActivity.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/settings/:orgId/activity/:tabName",
            beforeEnter: guardSecure,
            name: "UserActivityTab",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/UserActivity.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/tmds/create",
            beforeEnter: guardSecure,
            name: "TmdsCreate",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdCreate.vue"),
            },
        },
        {
            path: "/org/:orgId/events/future",
            beforeEnter: guardSecure,
            name: "FutureEvents",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/FutureEvents.vue"),
            },
        },
        {
            path: "/org/:orgId/tmds/view",
            beforeEnter: guardSecure,
            name: "tmds-view-page",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdPortfolio.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/tmds/view/decommissioned",
            beforeEnter: guardSecure,
            name: "TmdDecommission",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdDecommission.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/tmds/view/:tmdId",
            beforeEnter: guardSecure,
            name: "TmdView",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdView.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/tmds/edit/:tmdId",
            beforeEnter: guardSecure,
            name: "TmdEdit",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdEdit.vue"),
            },
        },
        {
            path: "/org/:orgId/tmds/view/:tmdId/:viewType",
            beforeEnter: guardSecure,
            name: "TmdViewDirect",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdView.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/tmds/workflows/view/:workflowId",
            beforeEnter: guardSecure,
            name: "TmdWorkflow",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdWorkflow.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/tmds/workflows/:tmdId",
            beforeEnter: guardSecure,
            name: "TmdCreateWorkflow",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdCreateWorkflow.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/tmds/versions/:tmdId/:eventId1/compare/:eventId2",
            beforeEnter: guardSecure,
            name: "TmdVersionCompare",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/TmdVersionCompare.vue"),
            },
        },
        {
            path: "/org/:orgId/report",
            beforeEnter: guardSecure,
            name: "OrgStandardReports",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/OrganisationReports.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/report/:viewType",
            beforeEnter: guardSecure,
            name: "OrgStandardReportsView",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/OrganisationReports.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/governance/workflows/create",
            beforeEnter: guardSecure,
            name: "CreateGovernanceWorkflow",
            components: {
                header: AppHeader,
                default: () =>
                    import("./modules/products/views/secure/CreateGovernanceWorkflow.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/governance/workflows/view/:workflowId",
            beforeEnter: guardSecure,
            name: "GovernanceWorkflow",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/GovernanceWorkflow.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/governance/workflows",
            beforeEnter: guardSecure,
            name: "GovernanceWorkflowPortfolio",
            components: {
                header: AppHeader,
                default: () =>
                    import("./modules/products/views/secure/GovernanceWorkflowPortfolio.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/fees/view",
            beforeEnter: guardSecure,
            name: "fees-library-page",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/FeeLibrary.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/fees/create",
            beforeEnter: guardSecure,
            name: "fee-create-page",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/FeeCreate.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/fees/view/:feeId",
            beforeEnter: guardSecure,
            name: "fee-view-page",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/FeeView.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/fees/view/:feeId/:viewType",
            beforeEnter: guardSecure,
            name: "FeeViewDirect",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/FeeView.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/fees/edit/:feeId",
            beforeEnter: guardSecure,
            name: "fee-edit-page",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/FeeEdit.vue"),
                footer: AppFooter,
            },
        },
        {
            path: "/org/:orgId/organisationWorkflows/:viewType?",
            beforeEnter: guardSecure,
            name: "ViewOrganisationWorkflows",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/OrganisationWorkflow.vue"),
            },
        },
        {
            path: "/org/:orgId/organisationWorkflows/closedProductWorkflows",
            beforeEnter: guardSecure,
            name: "ClosedProductWorkflows",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ClosedProductWorkflows.vue"),
            },
        },
        {
            path: "/org/:orgId/organisationWorkflows/closedTmdWorkflows",
            beforeEnter: guardSecure,
            name: "ClosedTmdWorkflows",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/ClosedTmdWorkflows.vue"),
            },
        },
        {
            path: "/org/:orgId/groupWorkflows/create/:type",
            beforeEnter: guardSecure,
            name: "CreateGroupWorkflowPage",
            components: {
                header: AppHeader,
                default: () => import("./modules/products/views/secure/CreateGroupWorkflowPage.vue"),
                footer: AppFooter,
            },
        },
    ],
    scrollBehavior: (to: any , from: any) => {
        if (to.hash) {
            return { el: to.hash };
        } else if (to.name === "Profile" && to.name === from.name) {
            return {};
        } else {
            return { left: 0, top: 0 };
        }
    },
});