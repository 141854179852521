import { FetchBase, fetchable } from './FetchBase'
import { ITargetMarket, IFetchable, ITargetMarketVersion } from './types';
import TmdService from '@/modules/products/services/TmdService'
import * as Generated from '@/domain/generated';

export class TargetMarketDetails extends FetchBase implements IFetchable<ITargetMarket>, ITargetMarket {
    private targetMarketService = new TmdService();
    public tmdId = '';
    public marketCategory = '';
    public name = '';
    public isWip = false;
    public isPublished = false;
    public workflowId?: string = undefined;
    public publishedVersion?: Generated.TargetMarket = undefined;
    public wipVersion?: Generated.TargetMarket = undefined;
    public productIds: string[] = [];
    public isDecommissioned = false;
    public lastUpdated?: string = undefined;

    @fetchable
    public async fetch(orgId: string, marketId: string): Promise<void> {
        this.reset();

        let targetMarketDetails = await this.targetMarketService.getTargetMarketItemDetails(orgId, marketId) as ITargetMarket;
        this.tmdId = targetMarketDetails.tmdId ? targetMarketDetails.tmdId : '';
        this.marketCategory = targetMarketDetails.marketCategory ? targetMarketDetails.marketCategory : '';
        this.name = targetMarketDetails.name ? targetMarketDetails.name : '';
        this.productIds = targetMarketDetails.productIds ? targetMarketDetails.productIds : [];
        this.isWip = targetMarketDetails.isWip ? targetMarketDetails.isWip : false;
        this.isPublished = targetMarketDetails.isPublished ? targetMarketDetails.isPublished : false;
        this.isDecommissioned = targetMarketDetails.isDecommissioned ? targetMarketDetails.isDecommissioned : false;
        this.workflowId = targetMarketDetails.workflowId ? targetMarketDetails.workflowId : '';
        this.publishedVersion = targetMarketDetails.publishedVersion ? targetMarketDetails.publishedVersion : undefined;
        this.wipVersion = targetMarketDetails.wipVersion ? targetMarketDetails.wipVersion : undefined;
    }

    private reset() {
        this.tmdId = '';
        this.marketCategory = '';
        this.name = '';
        this.isWip = false;
        this.isPublished = false;
        this.workflowId = undefined;
        this.publishedVersion = undefined;
        this.wipVersion = undefined;
        this.productIds = []
    }
}
