import * as Axios from 'axios';
import store from '@/store';
import { IOrganisationReport } from '@/domain/types';

export default class OrganisationReportService {
    public async getStandardReports(orgID: string): Promise<IOrganisationReport[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/reports`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await Axios.default(apiConfig)
        return res.data as IOrganisationReport[];
    }
}
