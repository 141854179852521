import * as Axios from "axios";
import BaseService from '../../../framework/services/BaseService';
import store from "@/store";
import productSchema from "@/domain/schemas/productV2Schema.json";
import * as Ajv from "ajv";
import { IConsoleMessage, IProduct, IValidationResult, IWorkflow } from "@/domain/types";
import * as jsonSourceMap from "json-source-map";
import { productValidator } from "@/utils/ProductValidator";

export default class ProductService extends BaseService {
    private apiBase: string;

    constructor() { super(); this.apiBase = store.state.apiBase; }

    public static validateProductData(productData: object): IValidationResult {
        let result: IValidationResult = {
            success: false,
            messages: []
        };

        const ajv:any = new Ajv.default({ allErrors: true, jsonPointers: true });

        if (!ajv.validate(productSchema, productData)) {
            if (ajv.errors) {
                const sourceMap: any = jsonSourceMap.stringify(productData, null, 2);

                for (let error of ajv.errors) {
                    let errorPointer: any = sourceMap.pointers[error.dataPath];
                    // errorMessage += "\n> " + jsonLines.slice(errorPointer.value.line, errorPointer.valueEnd.line).join("\n> ");

                    if (error.message) {
                        result.messages.push({
                            icon: "exclamation-triangle",
                            source: "Schema Error",
                            type: "Error",
                            category: error.keyword,
                            message: `(Line ${errorPointer.value.line + 1}, Col ${errorPointer.value.column}): ${error.message}`
                        });
                    }
                }
            }
        } else {
            result.messages.push({
                icon: "check-circle",
                source: "Schema Validator",
                type: "Confirmation",
                category: "Success",
                message: "Document validation successful"
            });
            result.success = true;
        }

        return result;
    }

    public static getHumanErrorMessages(productData: object): IConsoleMessage[] {
        return productValidator(productData);
    }

    public async createProduct(orgID: string, product: any): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "post",
            url: `${this.apiBase}/private/organisations/${orgID}/products`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                product: product
            }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data;
    }

    public async updateProduct(orgID: string, productID: string, product: any): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "post",
            url: `${this.apiBase}/private/organisations/${orgID}/products/${productID}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                product: product
            }
        };
        return this.makeAxiosCall(apiConfig);
    }

    public async getProduct(orgID: string, productID: any): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${this.apiBase}/private/organisations/${orgID}/products/${productID}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data;
    }

    public async getPublishedProduct(orgID: string, productID: any): Promise<IProduct> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${this.apiBase}/private/organisations/${orgID}/products/${productID}/published`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data as IProduct;
    }

    public async getWipProduct(orgID: string, productID: any): Promise<IProduct> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${this.apiBase}/private/organisations/${orgID}/products/${productID}/wip`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data as IProduct;
    }

    public async getDecommissionedProducts(orgID: string): Promise<IProduct[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${this.apiBase}/private/organisations/${orgID}/products/decommissioned`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data as IProduct[];
    }

    public async getWorkflowForProduct(organisationId: string, productId: string): Promise<IWorkflow[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/products/${productId}/workflows`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IWorkflow[];
    }
    
    public async fetchSyncData(baseUri: string | undefined, id: string): Promise<any> {
        if ( baseUri && !baseUri.endsWith('/')) baseUri += '/';
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${baseUri}cds-au/v1/banking/products/${id}`,
            headers: { 'x-v': 3, 'x-min-v': 1 }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data.data;
    }

    public async getAllProducts(organisationId:string): Promise<IProduct[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${this.apiBase}/private/organisations/${organisationId}/products/full`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data as IProduct[];
    }
}
