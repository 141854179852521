import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IConsoleMessage, ITargetMarket, ITargetMarketWorkflow } from '@/domain/types';
import { targetMarketValidator } from '@/utils/TargetMarketValidator';

export default class TargetMarketService extends BaseService {
    public async getTargetMarketData(orgID: string): Promise<ITargetMarket[]> { 
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/tmd`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as ITargetMarket[];
    }

    public async getTargetMarketItemDetails(orgID: string, marketId: string): Promise<ITargetMarket> { 
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/tmd/${marketId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as ITargetMarket;
    }
    
    public async createTmd(orgID: string, market: any): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "post",
            url: `${store.state.apiBase}/private/organisations/${orgID}/tmd`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                market
            }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data;
    }

    public async updateTmd(orgID: string, tmdID: string, market: any): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "post",
            url: `${store.state.apiBase}/private/organisations/${orgID}/tmd/${tmdID}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                market
            }
        };
        return this.makeAxiosCall(apiConfig);
    }

    public async getDecommissionedTargetMarket(orgID: string): Promise<ITargetMarket[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${store.state.apiBase}/private/organisations/${orgID}/tmd/decommissioned`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data as ITargetMarket[];
    }

    public async getWorkflowForTargetMarket(organisationId: string, tmdId: string): Promise<ITargetMarketWorkflow[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/tmd/${tmdId}/workflows`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as ITargetMarketWorkflow[];
    }

    public static getHumanErrorMessages(tmdData: any): IConsoleMessage[] {
        return targetMarketValidator(tmdData);
    }
}
