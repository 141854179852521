import { FetchBase, fetchable } from './FetchBase'
import IndustryService from '@/modules/products/services/IndustryService';
import { IFetchable, IMarketFavourites } from './types';

export class MarketFavourites extends FetchBase implements IFetchable<IMarketFavourites>, IMarketFavourites {
    public organisations!: { [orgId: string]: boolean; };
    public organisationFavourites!: { [orgId: string]: { [productId: string]: boolean; }; };
    public marketFavourites!: IMarketFavourites;
    
    private industryService = new IndustryService();

    @fetchable
    public async fetch() {
        let favourites = await this.industryService.getMarketFavourites();
        this.marketFavourites = favourites;
        this.organisationFavourites = favourites.organisationFavourites
        this.organisations = favourites.organisations
    }
}
