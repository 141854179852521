import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58df2d6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row mb-1"
}
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["id", "placeholder", "rows", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_warning_icon = _resolveComponent("warning-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.slots.label || _ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createElementVNode("span", {
                class: _normalizeClass(['data-h', {'data-mandatory': _ctx.required}])
              }, _toDisplayString(_ctx.label), 3)
            ], true),
            (_ctx.isWarning)
              ? (_openBlock(), _createBlock(_component_warning_icon, {
                  key: 0,
                  toolTipTitle: _ctx.warningTooltip
                }, null, 8, ["toolTipTitle"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("textarea", {
          id: _ctx.id,
          class: _normalizeClass([
          'data-v',
          _ctx.className,
          'form-control',
          `form-control-${_ctx.size}`,
          { 'error-border': _ctx.highlightError }
        ]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputModel) = $event)),
          placeholder: _ctx.placeholder,
          rows: _ctx.rows,
          disabled: _ctx.disabled
        }, "\n      ", 10, _hoisted_5), [
          [_vModelText, _ctx.inputModel]
        ])
      ])
    ])
  ], 64))
}