import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IWorkflow, WorkflowUpdateType, IAttachmentId } from '@/domain/types';

export default class WorkflowService extends BaseService {
    public async getWorkflows(organisationId: string): Promise<IWorkflow[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/workflows`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IWorkflow[];
    }

    public async getUserWorkflows(organisationId: string): Promise<IWorkflow[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/workflows/user/assigned`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IWorkflow[];
    }

    public async getWorkflow(organisationId: string, workflowId: string): Promise<IWorkflow> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/workflows/${workflowId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IWorkflow;
    }

    public async updateWorkflow(organisationId: string, workflowId: string, type: WorkflowUpdateType, comment: string, assignedTo: string, attachments?: IAttachmentId[]): Promise<void> {
        let apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/workflows/${workflowId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                update: {
                    type: type,
                    comment: comment,
                    attachments
                }
            }
        }

        if (assignedTo !== '') apiConfig.data.update.assignedTo = assignedTo;
        const res: any = await this.makeAxiosCall(apiConfig);
        if (res.status === 200 && type !== "COMMENT") {
            store.state.toastVisible = true;
        }
    }

    public async createWorkflow(organisationId: string, productId: string | undefined,isGroupWorkflow: boolean,productIds: string[] | [], comment: string, workflowType: string, futureDate: string | undefined): Promise<string> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/workflows`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                productId: productId,
                type: workflowType,
                comment: comment,
                futureDate: futureDate,
                productIds: productIds,
                isGroupWorkflow: isGroupWorkflow
            }
        }
        const res: any = await this.makeAxiosCall(apiConfig);
        if (res.status === 200) {
            store.state.toastText = comment;
            store.state.toastVisible = true;
        }
        return res.data.workflowId;
    }

    public async validateWorkflow(organisationId: string, productId: string | undefined,isGroupWorkflow: boolean,productIds: string[] | [], comment: string, workflowType: string, futureDate: string | undefined): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/workflows/validate`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                productId: productId,
                type: workflowType,
                comment: comment,
                futureDate: futureDate,
                productIds: productIds,
                isGroupWorkflow: isGroupWorkflow
            }
        }
        const res: any = await this.makeAxiosCall(apiConfig);
        return res;
    }
}
