import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IProductEvent, IMarketProductChangeHistory, IMarketProductAudit } from '@/domain/types';

export default class MarketHistoryService extends BaseService {
    public async getProductAudit(auditId: string): Promise<IMarketProductAudit> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/industry/audit/${auditId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IMarketProductAudit;
    }

    public async getMarketProductHistory(holderId: string, productId: string): Promise<IMarketProductChangeHistory[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/industry/holders/${holderId}/products/${productId}/changes`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IMarketProductChangeHistory[];
    }
}
