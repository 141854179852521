import * as Axios from "axios";
import BaseService from '../../../framework/services/BaseService';
import store from "@/store";
import { IGovernanceWorkflow, IGovernanceWorkflowTemplate } from '@/domain/types';

export default class GovernanceWorkflowService extends BaseService {
    public async createGovernanceWorkflow(organisationId: string, workflowData: any): Promise<string> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/governance/workflows`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: workflowData
        }
        const res: any = await this.makeAxiosCall(apiConfig);
        if (res.status === 200) {
            store.state.toastText = 'Initiated a new governance workflow.';
            store.state.toastVisible = true;
        }
        return res.data.govWorkFlowId;
    }

    public async getGovernanceWorkflow(organisationId: string, workflowId: string): Promise<IGovernanceWorkflow> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/governance/workflows/${workflowId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IGovernanceWorkflow;
    }

    public async getAllGovernanceWorkflows(organisationId: string): Promise<IGovernanceWorkflow[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/governance`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IGovernanceWorkflow[];
    }

    public async updateWorkflow(organisationId: string, workflowId: string, updateObject: any): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/governance/workflows/${workflowId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                update: updateObject
            }
        }
        const res: any = await this.makeAxiosCall(apiConfig);
        if (res.status === 200 && updateObject.type !== "COMMENT") {
            store.state.toastVisible = true;
        }
    }

    public async createGovernanceWorkflowTemplate(organisationId: string, templateData: any): Promise<string> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/governance/template`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: templateData
        }
        const res: any = await this.makeAxiosCall(apiConfig);
        if (res.status === 200) {
            store.state.toastText = 'A new governance workflow template created.';
            store.state.toastVisible = true;
        }
        return res.data.govWorkFlowId;
    }

    public async updateGovernanceWorkflowTemplate(organisationId: string, templateId: string, templateObject: any): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/governance/template/${templateId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                update: templateObject
            }
        }
        const res: any = await this.makeAxiosCall(apiConfig);
        if (res.status === 200) {
            store.state.toastText = `Updated the governance workflow template for ID: ${templateId}.`;
            store.state.toastVisible = true;
        }
    }

    public async getAllGovernanceWorkflowTemplates(organisationId: string): Promise<IGovernanceWorkflowTemplate[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/governance/templates`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IGovernanceWorkflowTemplate[];
    }

    public async getGovernanceWorkflowTemplate(organisationId: string, templateId: string): Promise<IGovernanceWorkflowTemplate> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/governance/template/${templateId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IGovernanceWorkflowTemplate;
    }
}
