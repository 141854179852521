<template>
    <div @click="$emit('select', organisationId)">
        <div class="org-container media pe-2 d-flex align-items-center justify-content-start">
            <div v-if="organisation.isFetching" class="spinner-container">
                <spinner/>
            </div>
            <div v-else class="d-flex align-items-center justify-content-start">
                <i class="fa fa-circle me-1" :class="{'white-bullet': !isCurrentOrg}"></i>
                <img v-lazy="'/' + organisation.iconUri" class="rounded-circle org-icon me-4">
                <h6 class="text-primary m-0 org-name"><b>{{ organisation.name }}</b></h6>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { IOrganisation, IFetchable } from '@/domain/types'
import { Organisation } from '@/domain';
import Spinner from '@/modules/products/components/Spinner.vue'
import { defineComponent, ref, computed, onMounted, nextTick } from "vue";
import store from "@/store";

export default defineComponent({
    name: 'header-org',
    components: {
        Spinner
    },
    props: {
        organisationId: {
            type: String
        }
    },
    setup(props) {

        const organisation = ref<IOrganisation & IFetchable<IOrganisation>>(new Organisation());

        const isCurrentOrg = computed(() => {
            return organisation.value.organisationId === store.state.organisation.organisationId
        })

        onMounted(() => {
            nextTick(() => {
                organisation.value.fetch(props.organisationId)
            })
        })

        return {
            isCurrentOrg,
            organisation
        }
    }
})
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/_functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
@import "~bootstrap/scss/_variables";

.org-container {
    cursor: pointer;
    .org-icon {
        padding: 0;
        width: 24px;
        height: 24px;
    }
}
.fa-circle {
    color: $primary;
    font-size: 12px;
}
.white-bullet {
    color: $white;
}

.fa-circle {
    color: $primary;
    font-size: 12px;
}

.white-bullet {
    color: $white;
}

</style>
