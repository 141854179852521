import { FetchBase, fetchable } from './FetchBase'
import IndustryService from '@/modules/products/services/IndustryService';
import { IFetchable, IMarketChanges, IMarketChange } from './types';

export class MarketChanges extends FetchBase implements IFetchable<IMarketChanges>, IMarketChanges {
    public date = '';
    public changes: IMarketChange[] = [];

    private industryService = new IndustryService();

    @fetchable
    public async fetch(date: string) {
        this.date = date;
        this.changes = await this.industryService.getMarketChanges(date);
    }
}
