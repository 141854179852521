import * as Generated from './generated'
import { FetchBase, fetchable } from './FetchBase'
import IndustryService from '@/modules/products/services/IndustryService';
import { IMarketProduct, IFetchable } from './types';

export class MarketProduct extends FetchBase implements IFetchable<IMarketProduct>, IMarketProduct {
    public product?: Generated.Product;

    private industryService: IndustryService = new IndustryService();

    @fetchable
    public async fetch(organisationID: string, productID: string) : Promise<void> {
        this.product = await this.industryService.getMarketProduct(organisationID, productID) as any;
    }
}
