import * as Axios from 'axios';
import store from '@/store';
import { ILendingRatesReportFilters, IComparisonReport } from '@/domain/types';

export default class ComparisonReportService {
    public async getComparisonReports(orgID: string, filter: ILendingRatesReportFilters): Promise<IComparisonReport[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/specificReports/comparison/lendingRates`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            params: {
                filter
            }
        }

        const res: Axios.AxiosResponse = await Axios.default(apiConfig)
        
        return res.data as IComparisonReport[];
    }
}