import { FetchBase, fetchable } from './FetchBase'
import EventsService from '@/modules/products/services/EventsService';
import { IFetchable, IFutureEvents, IFutureEvent } from './types';

export class FutureEvents extends FetchBase implements IFetchable<IFutureEvents>, IFutureEvents {
    public events: IFutureEvent[] = [];

    private eventsService = new EventsService();

    @fetchable
    public async fetch(orgID: string) {
        this.events = await this.eventsService.getFutureEvents(orgID);
    }
}
