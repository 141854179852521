import { FetchBase, fetchable } from './FetchBase'
import FeeHistoryService from '@/modules/products/services/FeeHistoryService';
import { IFetchable, IFeeHistory, IFeeEvent } from './types';

export class FeeChangeHistory extends FetchBase implements IFetchable<IFeeHistory>, IFeeHistory {
    public feeEvents: IFeeEvent[] = []

    private feeHistoryService = new FeeHistoryService();
    
    @fetchable
    public async fetch(orgID: string, feeId: string) {
        this.feeEvents = await this.feeHistoryService.getFeeEvents(orgID, feeId);
    }
    
}