import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IProductRate } from '@/domain/types';

export default class RatesService extends BaseService {
    public async getProductRates(orgID: string): Promise<IProductRate[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/specificReports/portfolio/rates`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IProductRate[];
    }
}
