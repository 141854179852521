import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { ITmdEventData, ITmdEvent, ITargetMarketWorkflow } from '@/domain/types';

export default class HistoryService extends BaseService {
    private async getTargetMarketEvents(orgID: string, tmdId: string): Promise<ITmdEventData[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/tmd/${tmdId}/events`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as ITmdEventData[];
    }

    private async getTargetMarketWorkflows(orgID: string, tmdId: string): Promise<ITargetMarketWorkflow[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/tmd/${tmdId}/workflows`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as ITargetMarketWorkflow[];
    }

    public async getTargetMarketHistory(orgID: string, tmdId: string): Promise<ITmdEvent[]> {
        let history: ITmdEvent[] = [];

        const tmdEvents: ITmdEventData[] = await this.getTargetMarketEvents(orgID, tmdId);
        const tmdWorkflows: ITargetMarketWorkflow[] = await this.getTargetMarketWorkflows(orgID, tmdId);

        for (let i = 0; i < tmdEvents.length; i++) {
            history.push({
                createdAt: tmdEvents[i].createdAt,
                tmdEvent: tmdEvents[i]
            })
        }
        for (let i = 0; i < tmdWorkflows.length; i++) {
            history.push({
                createdAt: tmdWorkflows[i].createdAt,
                workflow: tmdWorkflows[i]
            })
        }

        history.sort((a, b): number => {
            return Date.parse(b.createdAt) - Date.parse(a.createdAt);
        });

        return history;
    }

    public async getTargetMarketEvent(orgID: string, tmdId: string, eventId: string): Promise<ITmdEventData> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/tmd/${tmdId}/events/${eventId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as ITmdEventData;
    }
}
