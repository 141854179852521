<template>
  <div v-if="slots.label || label" class="row mb-1">
    <div class="col">
      <slot name="label">
        <span :class="['data-h', {'data-mandatory': required}]">{{ label }}</span>
      </slot>
      <conditional-input-icon v-if="showConditionalIcon" :tooltipTitle="conditionalIconTitle"/>
      <warning-icon v-if="isWarning" :toolTipTitle="warningTooltip" :appendedTitle="appendTitle" />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <input
        :class="[
          'data-v',
          'form-control',
          `form-control-${size}`,
          inputClass,
          rateChanged ? 'changed-input' : '',
          { 'error-border': highlightError }
        ]"
        v-model="inputModel"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :type="type"
        :min="min"
        :max="max"
        @keydown.enter="keyDownEnter"
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange($event)"
        @input="onInput($event)"

      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, ref, useSlots, watch } from "vue";
import WarningIcon from '@/modules/products/components/WarningIcon.vue';
import ConditionalInputIcon from "../../../products/components/product/creationFlow/ConditionalInputIcon.vue";
import currencyCodes from 'currency-codes';

export default defineComponent({
  name: 'form-input',
  props: {
    size: {
      type: String,
      default: "sm",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    inputClass: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false
    },
    showWarning: {
      type: Boolean,
      default: false
    },
    warningToolTipTitle: {
      type: String
    },
    appendedTitle: {
      type: String
    },
    showConditionalIcon: {
      type: Boolean,
      default: false
    },
    conditionalIconTitle: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    highlightError: {
      type: Boolean,
      default: false
    },
    min: [String, Number],
    max: [String, Number],
    formatter: Function,
    rateChanged: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WarningIcon,
    ConditionalInputIcon
  },
  emits:[
    "input",
    "change",
    "update:modelValue",
    "keyDownEnter",
    "onBlur",
    "onFocus"
  ],
  setup(props, { emit }) {

    const slots = useSlots()

    const inputModel = computed({
      get() {
        return props.modelValue;
      },
      set(value: any) {
        emit("update:modelValue", value);
      },
    });

    const onChange = (event:any) => {
      emit("change",event.target.value)
    }

    const onInput = (event:any) => {
      let value = event.target.value
      if (props.formatter) {
        inputModel.value = props.formatter(value)
      }
      emit("input", event.target.value)
    }

    const isWarning = computed(() => {
      if (props.required && !inputModel.value) return true;
      if (props.type && inputModel.value) {
        if (props.type == 'url') {
          return !isUrlValid(inputModel.value)
        } else {
          return isTypeValueValid(inputModel.value, props.type)
        }
      }
      if (props.showWarning) return true;
      return false;
    })

    const warningTooltip = computed(() => {
      if (props.required && !inputModel.value) return 'mandatoryField';
      if (props.type) {
        if (props.type == 'url') return 'invalidURL';
        if (props.type == 'rate' || props.type == 'amount' || props.type == 'currency') return 'invalidInput';
      }
      return props.warningToolTipTitle
    })

    const appendTitle = computed(() => {
      let title = ''
      switch(props.type) {
        case 'rate':
        case 'amount':
          title = '12.34.'
          break;
        case 'currency':
          title = 'AUD.'
          break
        default:
          title =  ''
          break
      }
      if (props.appendedTitle) title = props.appendedTitle
      return title
    })

    function isTypeValueValid(value: string, type: String): boolean {
      if (type === "currency") {
        return !currencyCodes.codes().includes(value)
      }
      let ret = false;
      let regex: any;
      switch(type) {
        case 'rate':
          regex = new RegExp("^(\\d{0,2}(\\.\\d{1,16})?|100(\\.00?)?)$", 'i');
          break;
        case "amount": 
          regex = new RegExp("^[-]?\\d{1,16}[.]\\d{2,16}$", 'i');
          break;
        case "currency":
          regex = new RegExp("^[-]?\\d{1,16}[.]?\\d{0,16}$", 'i');
          break;
        default:
          ret = true
          break;
      }

      if (regex) ret = !regex.test(value);
      return ret;
    }

    function isUrlValid(urlString: string): boolean {
      if (!urlString || urlString === "") return true;
      try {
        if (!(/^[^\s]+$/.test(urlString))) return false;
        const url = new URL(urlString);
      } catch (_) {
        return false;
      }
      return true;
    }

    function keyDownEnter(){
      emit("keyDownEnter")
    }

    function onBlur(){
      emit("onBlur")
    }
    function onFocus(){
      emit("onFocus")
    }

    return {
      inputModel,
      slots,
      isWarning,
      warningTooltip,
      appendTitle,
      onChange,
      keyDownEnter,
      onBlur,
      onFocus,
      onInput
    };
  },
});
</script>

<style scoped lang="scss">
.changed-input {
    border-color: rgb(87, 87, 255) !important;
    opacity: 70% !important;
    // color: white !important;
}

.error-border {
  border: 1px solid red !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>