import { FetchBase, fetchable } from './FetchBase'
import TMDWorkflowService from '@/modules/products/services/TmdWorkflowService';
import { IFetchable, ITargetMarketWorkflows, ITargetMarketWorkflow } from './types';

export class WorkflowForTargetMarket extends FetchBase implements IFetchable<ITargetMarketWorkflows>, ITargetMarketWorkflows {
    public tmdWorkflows: ITargetMarketWorkflow[] = [];

    private tmdWorkflowService = new TMDWorkflowService();

    @fetchable
    public async fetch(orgID: string) {
        let workflows: ITargetMarketWorkflow[] = [];
        workflows = await this.tmdWorkflowService.getAllTmdWorkflows(orgID)
        this.tmdWorkflows = workflows;
    }
}
