import { FetchBase, fetchable } from './FetchBase'
import WorkflowService from '@/modules/products/services/WorkflowService';
import { IFetchable, IWorkflow, WorkflowState, IWorkflowComment, WorkflowUpdateType, WorkflowType, IAttachmentId } from './types';

export class Workflow extends FetchBase implements IFetchable<IWorkflow>, IWorkflow {
    public workflowId = '';
    public organisationId = '';
    public productId?: string = '';
    public createdAt = '';
    public userId = '';
    public assignedTo = '';
    public state: WorkflowState = WorkflowState.OPEN;
    public type: WorkflowType = WorkflowType.PUBLISH;
    public futureDate?: string = undefined;
    public comments: IWorkflowComment[] = [];
    public attachments?: any[] | undefined;
    public productIds?: string[] = [];
    public isGroupWorkflow?: boolean;

    private workflowService = new WorkflowService();

    public static async createWorkflow(organisationId: string, productId: string | undefined,isGroupWorkflow: boolean,productIds: string[] | [], comment: string, workflowType: string, futureDate: string | undefined): Promise<string> {
        let workflowService = new WorkflowService();
        return workflowService.createWorkflow(organisationId, productId, isGroupWorkflow, productIds, comment, workflowType, futureDate);
    }

    public async updateWorkflow(type: WorkflowUpdateType, comment: string, assignedTo: string, attachments?: IAttachmentId[]) {
        await this.workflowService.updateWorkflow(this.organisationId, this.workflowId, type, comment, assignedTo, attachments);
    }

    @fetchable
    public async fetch(orgID: string, workflowId: string) {
        let workflow: IWorkflow = await this.workflowService.getWorkflow(orgID, workflowId);

        this.workflowId = workflow.workflowId;
        this.organisationId = workflow.organisationId;
        this.productId = workflow.productId;
        this.createdAt = workflow.createdAt;
        this.userId = workflow.userId;
        this.assignedTo = workflow.assignedTo;
        this.state = workflow.state;
        this.type = (workflow.type) ? workflow.type : WorkflowType.PUBLISH;
        this.futureDate = (workflow.futureDate) ? workflow.futureDate : undefined;
        this.comments = workflow.comments;
        this.attachments = workflow.attachments ? workflow.attachments : undefined;
        this.productIds = workflow.productIds ? workflow.productIds : [];
        this.isGroupWorkflow = workflow.isGroupWorkflow ? workflow.isGroupWorkflow : false;
    }
}
